<template>
  <el-container
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100vh;
    "
  >
    <el-main style="flex-grow: 1; width: 100%">
      <div
        class="content_box"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <h1 style="margin-top: 0px; font-size: 20px">
          安知职业培训线上平台追溯日志
        </h1>
        <el-table
          :show-header="false"
          :data="nameDate"
          border
          style="width: 100%; margin-top: 30px"
        >
          <el-table-column prop="name"> </el-table-column>
          <el-table-column prop="value"> </el-table-column>
        </el-table>
        <el-table
          :show-header="false"
          :data="userData"
          border
          style="width: 100%; border-top-width: 0"
        >
          <el-table-column prop="date"> </el-table-column>
          <el-table-column prop="name"> </el-table-column>
          <el-table-column prop="time"> </el-table-column>
          <el-table-column prop="type"> </el-table-column>
        </el-table>
        <el-table :data="list" border style="width: 100%; border-top-width: 0">
          <el-table-column prop="createTime" label="时间"> </el-table-column>
          <el-table-column prop="catalogName" label="信息"> </el-table-column>
        </el-table>
      </div>
    </el-main>
    <el-footer style="padding: 20px">
      <!-- <div class="box"> -->
      <el-pagination
        background
        small
        layout="prev, pager, next"
        :total="parseInt(apiData.total)"
        :current-page="apiData.current"
        @current-change="getApi"
      >
      </el-pagination>
      <!-- </div> -->
    </el-footer>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      apiData: {
        total: 0,
        current: 1,
      },
      info: {},
      list: [],
      nameDate: [],
      userData: [],
    };
  },
  beforeCreate() {
    this.$router.push({
      path:"/web/zhengzhouH5/logs2",
      query:{
        json:this.$route.query.json
      }
    })
  },
  mounted() {
    // this.getApi();
  },
  methods: {
    getApi(pageNum = 1) {
      this.$post("/biz/heNan/queryH5/queryLearningTrajectory", {
        json: this.$route.query.json,
        pageNum: pageNum,
        pageSize: 10,
      })
        .then((res) => {
          ;
          this.info = res.data.info;
          this.nameDate = [{ name: "姓名", value: this.info.userName }];
          this.userData = [
            {
              date: "身份证号",
              name: this.info.idCard,
              time: "联系电话",
              type: this.info.mobile,
            },
            {
              date: "培训工种",
              name: this.info.occupationName,
              time: "学习形式",
              type: "线上",
            },
            {
              date: "所在班级批次",
              name: this.info.projectCode,
              time: "学习所属区(县)主管部门",
              type: this.info.areaName,
            },
            {
              date: "所在班级",
              name: this.info.projectName,
              time: "完成课程折合学时",
              type: this.info.studyLessonNum,
            },
          ];
          this.apiData.total = res.data.list.total;
          this.list = res.data.list.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.el-table td,
.el-table th {
  text-align: center;
}
.box {
  display: flex;
  justify-content: center;
}
</style>